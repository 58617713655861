export const state = {
  token: localStorage.getItem('user-token') || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  services: [],
  serviceSelected: [],
  projectSelected: [],
  ticket: [],
  tickets: [],
  label: 'survey',
  search: null,
  idStatus: 3,
  techs: [],
  statusHistory: [],
  statusHistoryProject: [],
  statusHistoryTicket: [],
  clients: [],
  managers: [],
  taxes: null,
  filterProject: 21,
  filterService: 3,
  notifications: [],
  serviceActives: 0,
  projectActives: 0,
  servicePagination: 1,
  terms: [],
  projects: [],
  status: [],
  status_project: [],
  status_ticket: [],
  calendar: false,
  roles: [],
  notificationsCount: 0,
  dates: [],
  lat: null,
  lng: null,
  clock: {
    checkin: false,
    clock: null,
    id: null,
  },
  time_cards: [],
  modalProyect: false,
  idStatusProject: 0,
  toggleMultiple: 0,
};
