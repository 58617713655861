
export const mutations = {
  resetState(state) {
    state.token = localStorage.getItem('user-token') || '';
    state.user = JSON.parse(localStorage.getItem('user')) || {};
    state.calendar = false;
    state.search = null;
    state.idStatus = 3;
    state.techs = [];
    state.serviceActives = 0;
    state.projectActives = 0;
    state.filterProject = 21;
    state.filterService = 3;
    state.notificationsCount = 0;
    state.clients = [];
    state.services = [];
    state.projects = [];
    state.serviceSelected = [];
    state.projectSelected = [];
    state.ticket = [];
    state.tickets = [];
    state.label = 'survey';
    state.managers = [];
    state.status = [];
    state.status_project = [];
    state.status_ticket = [];
    state.roles = [];
    state.dates = [];
    state.clock = {
      checkin: false,
      clock: null,
      id: null,
    };
    state.taxes = null;
    state.terms = [];
    state.time_cards = [];
    state.modalProyect = false;
    state.lat = null;
    state.lng = null;
    state.notifications = [];
  },
  setIdStatus(state, idStatus) {
    state.idStatus = idStatus;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setLabel(state, label) {
    state.label = label;
  },
  editNotifications(state, notification) {
    const index = state.notifications.findIndex(
      ({ id }) => id === notification.id,
    );
    if (index !== -1) {
      state.notifications.splice(index, 1, notification);
    }
  },
  setServicePagination(state, servicePagination) {
    state.servicePagination = servicePagination;
  },
  setServiceActives(state, active) {
    state.serviceActives = active;
  },
  setProjectActives(state, active) {
    state.projectActives = active;
  },
  sumProjectActives(state) {
    state.projectActives++;
  },
  setNotificationsCount(state, count) {
    state.notificationsCount = count;
  },
  addNotificationsCount(state) {
    state.notificationsCount--;
  },
  deductNotificationsCount(state) {
    state.notificationsCount++;
  },
  setLocation(state, { lat, lng }) {
    state.lat = lat;
    state.lng = lng;
  },
  setTaxes(state, taxes) {
    state.taxes = taxes;
  },
  setDates(state, dates) {
    state.dates = dates;
  },
  addDates(state, date) {
    state.dates.unshift(date);
  },
  setTerms(state, terms) {
    state.terms = terms;
  },
  setTerm(state, term) {
    state.terms.unshift(term);
  },
  editTerm(state, term) {
    const index = state.terms.findIndex(({ id }) => id === term.id);
    if (index !== -1) {
      state.terms.splice(index, 1, term);
    }
  },
  deleteTerm(state, index) {
    state.terms.splice(index, 1);
  },
  setToken(state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  setCalendar(state, calendar) {
    state.calendar = calendar;
  },
  setSearch(state, search) {
    state.search = search;
  },

  setTechs(state, techs) {
    state.techs = techs;
  },
  setClients(state, clients) {
    state.clients = clients;
  },
  setManagers(state, managers) {
    state.managers = managers;
  },
  setStatusServices(state, status) {
    state.status = status;
  },
  setStatusProjects(state, status_project) {
    state.status_project = status_project;
  },
  setStatusTickets(state, status_ticket) {
    state.status_ticket = status_ticket;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  SET_CHECK(state, clock) {
    state.clock = clock;
  },
  serTimeCards(state, time_cards) {
    state.time_cards = time_cards;
  },
  setModalProyect(state, modalProyect) {
    state.modalProyect = modalProyect;
  },
  setStatus(state, { idService, oldValue, newValue, status }) {
    const indexOld = state.status.findIndex(({ id }) => id == oldValue);
    if (indexOld !== -1) {
      const st = { ...state.status[indexOld] };
      st.count = parseInt(st.count) - 1;
      state.status.splice(indexOld, 1, st);
    }
    const indexNew = state.status.findIndex(({ id }) => id == newValue);
    if (indexNew !== -1) {
      const st = { ...state.status[indexNew] };
      st.count = parseInt(st.count) + 1;
      state.status.splice(indexNew, 1, st);
    }

    const index = state.services.findIndex(({ id }) => id == idService);
    if (index !== -1) {
      state.services.splice(index, 1);
    }

    if (idService == state.serviceSelected.id) {
      let serviceSelected = state.serviceSelected;
      state.serviceSelected = [];
      state.serviceSelected = serviceSelected;
      state.serviceSelected.status_id = status.id;
      state.serviceSelected.status = status;
    }
  },
  addfileService(state, data) {
    if (state.serviceSelected.id == data.id) {
      let serviceSelected = state.serviceSelected;
      state.serviceSelected = [];
      state.serviceSelected = serviceSelected;
      if (data.mimetype != 'application/pdf') {
        state.serviceSelected.images.unshift(data.data);
      } else {
        state.serviceSelected.docs.unshift(data.doc);
      }
    }
  },
  addfileTicket(state, data) {
    if (state.ticket.id == data.id) {
      let ticket = state.ticket;
      state.ticket = [];
      state.ticket = ticket;
      if (data.mimetype != 'application/pdf') {
        state.ticket.images.unshift(data.data);
      } else {
        state.ticket.docs.unshift(data.doc);
      }
    }
  },
  deletefileService(state, data) {
    if (state.serviceSelected.id == data.item.detail_id) {
      if (data.item.type === 'image') {
        const indexService = state.serviceSelected.images.findIndex(
          ({ id }) => id === data.item.id,
        );
        if (indexService !== -1) {
          state.serviceSelected.images.splice(indexService, 1);
        }
      } else {
        const index = state.serviceSelected.docs.findIndex(
          ({ id }) => id === data.item.id,
        );
        if (index !== -1) {
          state.serviceSelected.docs.splice(index, 1);
        }
      }
      let service = state.serviceSelected;
      state.serviceSelected = [];
      state.serviceSelected = service;
    }
  },
  deletefileTicket(state, data) {
    if (state.ticket.id == data.item.detail_id) {
      let service = state.ticket;
      state.ticket = [];
      state.ticket = service;
      if (data.item.type === 'image') {
        const indexTicket = state.ticket.images.findIndex(
          ({ id }) => id === data.item.id,
        );
        if (indexTicket !== -1) {
          state.ticket.images.splice(indexTicket, 1);
        }
      } else {
        const index = state.ticket.docs.findIndex(
          ({ id }) => id === data.item.id,
        );
        if (index !== -1) {
          state.ticket.docs.splice(index, 1);
        }
      }
    }
  },
  setStatusProject(state, { oldValue, newValue }) {
    const indexOld = state.status_project.findIndex(
      ({ id }) => id === oldValue,
    );
    if (indexOld !== -1) {
      const st = { ...state.status_project[indexOld] };
      st.count -= 1;
      state.status_project.splice(indexOld, 1, st);
    }
    const indexNew = state.status_project.findIndex(
      ({ id }) => id == newValue,
    );
    if (indexNew !== -1) {
      const st = { ...state.status_project[indexNew] };
      st.count = parseInt(st.count) + 1;
      state.status_project.splice(indexNew, 1, st);
    }
  },
  setStatusTicket(state, { oldValue, newValue }) {
    const indexOld = state.status_ticket.findIndex(
      ({ id }) => id === oldValue,
    );
    if (indexOld !== -1) {
      const st = { ...state.status_ticket[indexOld] };
      st.count -= 1;
      state.status_ticket.splice(indexOld, 1, st);
    }
    const indexNew = state.status_ticket.findIndex(
      ({ id }) => id == newValue,
    );
    if (indexNew !== -1) {
      const st = { ...state.status_ticket[indexNew] };
      st.count = parseInt(st.count) + 1;
      state.status_ticket.splice(indexNew, 1, st);
    }
  },
  setFilterService(state, filter) {
    state.filterService = filter;
  },
  setFilterProject(state, filter) {
    state.filterProject = filter;
  },
  addProject(state, newProject) {
    const index = state.status_ticket.findIndex(
      ({ id }) => id == newProject.status_id,
    );
    if (index !== -1) {
      const status = { ...state.status_ticket[index] };
      status.count = parseInt(status.count) + 1;
      state.status_ticket.splice(index, 1, status);
    }
    if (state.filterProject == state.status_ticket[index].id) {
      state.projects.unshift(newProject);
    }
  },
  addUser(state, data) {
    switch (data.data.type) {
      case 'customer':
        state.clients.push(data.data);
        break;

      case 'technician':
        state.techs.push(data.data);
        break;
      case 'manager':
        state.managers.push(data.data);
        break;
      default:
        break;
    }
    state.roles = data.roles;
  },
  addTicket(state, data) {
    if (state.projectSelected.id == data.project_id) {
      state.tickets.unshift(data.data);
    }
    const index = state.projects.findIndex(({ id }) => id == data.project_id);
    if (index !== -1) {
      state.projects[index].tickets.unshift(data.data);
    }
  },
  addService(state, newService) {
    const index = state.status.findIndex(
      ({ id }) => id === newService.status_id,
    );
    if (index !== -1) {
      const status = { ...state.status[index] };
      status.count = parseInt(status.count) + 1;
      state.status.splice(index, 1, status);
    }
    if (state.filterService == state.status[index].id) {
      state.services.unshift(newService);
    }
  },
  setServices(state, services) {
    state.services = services;
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setService(state, service) {
    state.serviceSelected = service;
  },
  setproject(state, project) {
    state.projectSelected = project;
  },
  setStatusHistory(state, logs){
    state.statusHistory = logs;
  },
  setStatusHistoryProject(state, logs){
    state.statusHistoryProject = logs;
  },
  setStatusHistoryTicket(state, logs){
    state.statusHistoryTicket = logs;
  },
  setTicket(state, data) {
    state.ticket = data;
  },
  updTicket(state, data) {
    if (state.ticket.id == data.data.id) {
      state.ticket = data.data;
    }
  },
  setTickets(state, data) {
    state.tickets = data;
  },
  deleteNotesTickets(state, data) {
    const index = state.tickets.findIndex(({ id }) => id == data.id);
    if (index !== -1) {
      state.tickets[index].notes = data.data;
    }
    if (state.ticket.id == data.id) {
      state.ticket.notes = data.data;
    }
  },
  setNoteTicket(state, data) {
    if (state.ticket.id == data.id) {
      state.ticket.notes.unshift(data.data);
    }
  },
  updateTicket(state, data) {
    if (data.project_id == state.projectSelected.id) {
      const index = state.tickets.findIndex(({ id }) => id == data.data.id);
      if (index !== -1) {
        const ticket = { ...state.tickets[index], ...data.data };
        state.tickets.splice(index, 1, ticket);
        state.ticket = ticket
      }
    }
  },
  deleteTicket(state, ticket) {
    const index = state.tickets.findIndex(({ id }) => id === ticket);
    if (index !== -1) {
      state.tickets.splice(index, 1);
    }
  },
  AddTiketImg(state, img) {
    state.ticket.images.unshift(img);
    const index = state.tickets.findIndex(({ id }) => id === img.detail_id);
    if (index !== -1) {
      state.tickets[index].images.unshift(img);
    }
  },
  deleteTiketImg(state, idImage) {
    const index = state.ticket.images.findIndex(({ id }) => id === idImage);
    let indexTicket;
    if (index !== -1) {
      indexTicket = state.tickets.findIndex(
        ({ id }) => id === state.ticket.id,
      );
      state.ticket.images.splice(index, 1);
    }
    if (indexTicket !== -1) {
      const indexImage = state.tickets[indexTicket].images.findIndex(
        ({ id }) => id === idImage,
      );
      state.tickets[indexTicket].images.splice(indexImage, 1);
    }
  },
  deleteProject(state, idProject) {
    const index = state.projects.findIndex(({ id }) => id == idProject);
    if (index !== -1) {
      const indexStatusOld = state.status_ticket.findIndex(
        ({ id }) => id == state.projects[index].status_id,
      );
      if (indexStatusOld !== -1) {
        const status = { ...state.status_ticket[indexStatusOld] };
        status.count = parseInt(status.count) - 1;
        state.status_ticket.splice(indexStatusOld, 1, status);
      }
      state.projects.splice(index, 1);
    }
  },
  updateProject(state, project) {
    const indexStatusNew = state.status_ticket.findIndex(
      ({ id }) => id == project.status_id,
    );
    if (indexStatusNew !== -1) {
      const status = { ...state.status_ticket[indexStatusNew] };
      status.count = parseInt(status.count) + 1;
      state.status_ticket.splice(indexStatusNew, 1, status);
    }

    const indexStatusOld = state.status_ticket.findIndex(
      ({ id }) => id == state.projectSelected.status_id,
    );
    if (indexStatusOld !== -1) {
      const status = { ...state.status_ticket[indexStatusOld] };
      status.count = parseInt(status.count) - 1;
      state.status_ticket.splice(indexStatusOld, 1, status);
    }
    const index = state.projects.findIndex(({ id }) => id == project.id);
    if (index !== -1) {
      if (indexStatusNew == indexStatusOld) {
        const projectNew = { ...state.projects[index], ...projectNew };
        state.projects.splice(index, 1, projectNew);
      } else {
        state.projects.splice(index, 1);
      }
    }

    if (state.projectSelected.id == project.id) {
      let projectSelected = { ...state.projectSelected, ...project };
      state.projectSelected = projectSelected;
    }
  },
  updateImageProject(state, data) {
    if (state.projectSelected.id == data.doc.detail_id) {
      state.projectSelected.files.unshift(data.doc);
    }
  },
  deleteFileProject(state, data) {
    const index = state.projectSelected.files.findIndex(
      ({ id }) => id == data.item.id,
    );
    if (index !== -1) {
      state.projectSelected.files.splice(index, 1);
    }
  },
  deleteEstimate(state, data) {
    const index = state.projectSelected.estimates.findIndex(
      ({ id }) => id == data.idEstimate,
    );
    if (index !== -1) {
      state.projectSelected.estimates.splice(index, 1);
    }
  },
  updateEstimate(state, data) {
    if (data.projectId == state.projectSelected.id) {
      const index = state.projectSelected.estimates.findIndex(
        ({ id }) => id === data.estimate.id,
      );
      if (index !== -1) {
        state.projectSelected.estimates.splice(index, 1, data.estimate);
      } else {
        state.projectSelected.estimates.unshift(data.estimate);
      }
    }
  },
  updateService(state, service) {
    const index = state.services.findIndex(({ id }) => id === service.id);
    if (index !== -1) {
      const ser = { ...state.services[index], ...service };
      state.services.splice(index, 1, ser);
    }
  },
  deleteService(state, service) {
    const index = state.services.findIndex(({ id }) => id == service);
    const index2 = state.status.findIndex(
      ({ id }) => id === state.services[index].status_id,
    );
    if (index2 !== -1) {
      const st = { ...state.status[index2] };
      st.count = parseInt(st.count) - 1;
      state.status.splice(index2, 1, st);
    }
    if (index !== -1) {
      state.services.splice(index, 1);
    }
  },
  setIdStatusProject(state, id){
    state.idStatusProject = id;
  },
  setToggleMultiple(state, id){
    state.toggleMultiple = id;
  }
}