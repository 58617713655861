<template justify='center'>
  <v-container>
    <v-row>
      <v-col
        v-for='(item, i) in items'
        :key='i'
        v-show="
          item.type == user.type ||
            user.type == 'superadmin' ||
            item.type == 'all'
        "
        class='body-card'
      >
        <v-hover v-slot:default='{ hover }' open-delay='200'>
          <v-card
            :to='item.href'
            class='mb-3'
            width='270'
            color='grey lighten-3'
            :elevation='hover ? 16 : 2'
          >
            <v-list-item>
              <v-list-item-avatar class='icon' size='80' :color='item.color'>
                <v-icon size='50' color='white'>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class='mb-3 tittle'>{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class='mx-6' :color='item.color'></v-divider>
            <p class='footer-card'>{{ item.msj }}</p>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  data: () => ({
    width: 100,
    height: 100,
    elevation: 4,
    color: 'black',
    tile: true,
    items: [
      {
        icon: 'mdi-clipboard-text',
        text: 'Services',
        href: '/services',
        msj: ' active services',
        color: '#90caf9',
        type: 'all',
      },
      {
        icon: 'mdi-folder',
        text: 'Projects',
        href: '/projects',
        msj: '',
        color: '#0d47a1',
        type: 'all',
      },
      {
        icon: 'mdi-store ',
        text: 'Inventory',
        href: '/inventory',
        msj: '',
        color: '#00bfa5',
        type: 'admin',
      },
      {
        icon: 'mdi-email',
        text: 'Email',
        href: '/email',
        msj: '',
        color: '#b71c1c',
        type: 'admin',
      },
      {
        icon: 'mdi-calendar',
        text: 'Calendar',
        href: '/calendar',
        msj: '',
        color: '#2962ff',
        type: 'admin',
      },
      {
        icon: 'mdi-timer-outline',
        text: 'Time Cards',
        href: '/time-cards',
        msj: '',
        color: '#5e35b1',
        type: 'all',
      },
      {
        icon: 'mdi-google-drive',
        text: 'Drive',
        href: '/drive',
        msj: '',
        color: '#ffd600',
        type: 'admin',
      },
      {
        icon: 'mdi-currency-usd',
        text: 'Accounting',
        href: '/',
        msj: '',
        color: '#6f6f6f',
        type: 'admin',
      },
      {
        icon: 'mdi-account-multiple',
        text: 'Users',
        href: '/users',
        msj: '',
        color: '#7b1fa2',
        type: 'admin',
      },
      {
        icon: 'mdi-cog',
        text: 'Settings',
        href: '/settings',
        msj: '',
        type: 'admin',
        color: '#607d8b',
      },
    ],
  }),
  mounted() {
    this.getInit();
  },
  computed: {
    ...mapState(['user', 'token', 'serviceActives', 'projectActives']),
  },
  watch: {
    serviceActives() {
      this.items[0].msj = this.serviceActives + ' Services actives';
      this.items[1].msj = this.projectActives + ' Current Projects';
    },
  },
  methods: {
    ...mapMutations(['setNotifications']),
    getInit() {
      this.items[0].msj = this.serviceActives + ' Services actives';
      this.items[1].msj = this.projectActives + ' Current Projects';
    },
  },
};
</script>
