<template>
  <v-app id="inspire">
    <div
      v-if="
        $route.path !== '/login' &&
          $route.fullPath !== `/services/${$route.params.id}` &&
          $route.fullPath !==
            `/projects/${$route.params.id}/${$route.params.so}`
      "
    >
      <div v-if="loader">
        <v-sheet class="pa-3">
          <v-skeleton-loader
            class="mx-auto"
            max-width="90%"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <!-- aside menu -->
        <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          app
        >
          <v-list nav>
            <v-list-item-group v-model="item">
              <v-list-item
                v-for="(item, i) in items"
                v-show="
                  item.type == user.type ||
                    user.type == 'superadmin' ||
                    item.type == 'all'
                "
                :key="i"
                :to="item.href"
                exact
                :disabled="!item.active"
                class="menu"
              >
                <v-list-item-action>
                  <v-icon class="v-icon">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-slot:append>
            <div class="pa-2">
              <v-btn block color="#081b38" class="btn-logout" @click="logout()">
                Logout
              </v-btn>
            </div>
          </template>
        </v-navigation-drawer>
        <!-- end aside menu -->
        <!-- header  -->
        <v-app-bar
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          app
          color="#081b38"
          dark
          height="80px"
        >
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-toolbar-title class="ml-0 pl-4">
            <v-btn text large class="d-none d-sm-flex img">
              <img width="120" alt="Vue logo" src="./assets/img/logo.png" />
            </v-btn>
          </v-toolbar-title>
          <v-text-field
            v-if="$route.path !== '/users'"
            v-model="searchAlgolia"
            v-on:keyup.enter="search(searchAlgolia, 'tabla')"
            flat
            hide-details
            label="search by name, address, city, PO, PM, etc..."
            class="input-file"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="searchByUser"
            v-on:keyup.enter="searchuser(searchByUser)"
            flat
            hide-details
            label="search users"
            class="hidden-sm-and-down input-file"
          ></v-text-field>
          <v-btn
            v-if="$route.path !== '/users'"
            icon
            dark
            :loading="btnLoader"
            @click="search(searchAlgolia, 'tabla')"
          >
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            dark
            :loading="btnLoader"
            @click="searchuser(searchAlgolia)"
          >
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <section id="app" class="section hidden-sm-and-down">
            <p class="time">
              {{ hour }}:{{ minute }}<small>:{{ secons }}</small>
            </p>
          </section>
          <v-btn
            v-if="!clock.checkin"
            color="green"
            class="hidden-sm-and-down"
            :loading="loading"
            @click="checkin()"
          >
            <p class="clock">Clock In</p>
          </v-btn>
          <v-btn
            v-else
            color="red"
            class="hidden-sm-and-down"
            :loading="loading"
            @click="checkout()"
          >
            <p class="clock">Clock Out</p>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="green"
            class="hidden-sm-and-down"
            :loading="loading"
            @click="reload()"
          >
            <p style="font-size: 5px; margin: 0;">Reload Data</p>
          </v-btn>
          <v-menu offset-y :close-on-content-click="false" max-height="350px">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :content="notificationsCount"
                :value="notificationsCount"
                color="green"
                overlap
              >
                <v-icon v-if="notificationsCount > 0" v-bind="attrs" v-on="on">
                  mdi-bell
                </v-icon>
                <v-icon v-else v-bind="attrs" v-on="on"
                  >mdi-bell-outline</v-icon
                >
              </v-badge>
            </template>
            <v-list>
              <v-list-item>
                <v-btn
                  color="#081b38"
                  block
                  :loading="refreshLoader"
                  @click="refresh"
                  style="color: white;"
                >
                  <v-icon color="white">mdi-refresh</v-icon>refresh
                  notifications
                </v-btn>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in notifications.slice(0, 3)"
                :key="index"
              >
                <v-checkbox
                  v-model="item.read"
                  @click="changeNoty(item)"
                ></v-checkbox>

                <v-list-item-content
                  @click="goService(item.detail_id)"
                  style="cursor: pointer;"
                >
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-btn
                  color="#081b38"
                  block
                  to="/actions-items"
                  style="color: white;"
                >
                  View All
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>

          <p class="name d-none d-sm-flex">{{ user.name }}</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon large class="porfile" v-bind="attrs" v-on="on">
                <v-avatar size="32px" item>
                  <v-img :src="user.avatar" alt="Vuetify"></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list class="btn-reload">
              <v-list-item>
                <v-list-item-title @click="reload()"
                  >Reload Data</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="logout()">Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <!-- end header -->
        <!-- content page -->
        <v-main>
          <v-container class="fill-height" fluid>
            <v-row>
              <v-col cols="2"> </v-col>
              <v-col cols="6" v-if="algolia.length >= 1">
                <v-card elevation="2" class="algolia">
                  <v-btn
                    color="red"
                    block
                    style="color: white;"
                    @click="algolia = []"
                  >
                    close
                  </v-btn>
                  <div
                    class="algolia-div"
                    v-for="(item, i) in algolia"
                    :key="i"
                    @click="search(item, 'algolia')"
                  >
                    <h3>{{ item.name }}</h3>
                    <h5>{{ item.address }}</h5>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-alert
                v-model="alert"
                color="red"
                dismissible
                elevation="15"
                type="warning"
                style="z-index: 1000;"
                >You cannot log out, you have the time card active!</v-alert
              >
              <router-view />
            </v-row>
          </v-container>
        </v-main>
        <!--  end content page -->
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import store from '@/store';
import moment from 'moment';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import apis from '@/apis';
import algolia from '@/apis/algolia';
import google from '@/apis/google';

export default {
  props: {
    source: String,
  },
  computed: {
    ...mapState([
      'user',
      'token',
      'clock',
      'time_cards',
      'notifications',
      'notificationsCount',
      'projectSelected',
      'idStatus',
      'serviceSelected',
      'idStatusProject',
    ]),
    countNotify() {
      var count = 0;
      this.notifications.map((notification) => {
        if (notification.read === 0 || notification.read === false) {
          count++;
        }
      });
      return count;
    },
  },
  data: () => ({
    searchClient: algoliasearch(
      `${process.env.API_ALGOLIA_NAME}`,
      `${process.env.API_ALGOLIA}`,
    ),
    currentTime: null,
    btnLoader: false,
    searchAlgolia: null,
    searchByUser: null,
    hour: null,
    accept: 'application/json',
    dateFormat: 'YYYY-MM-DD H:mm:ss',
    check: false,
    checkinvar: null,
    checkoutvar: null,
    alert: false,
    refreshLoader: false,
    minute: null,
    secons: null,
    loader: true,
    idcheck: null,
    drawer: null,
    algolia: [],
    algoliaType: 'services',
    loading: false,
    item: 1,
    noty: 0,
    channel: null,
    items: [
      { icon: 'mdi-home', text: 'Home', href: '/', active: true, type: 'all' },
      {
        icon: 'mdi-clipboard-text',
        text: 'Services',
        href: '/services',
        active: true,
        type: 'all',
      },
      {
        icon: 'mdi-folder',
        text: 'Projects',
        href: '/projects',
        active: true,
        type: 'all',
      },
      {
        icon: 'mdi-store ',
        text: 'Inventory',
        href: '/inventory',
        active: true,
        type: 'admin',
      },
      {
        icon: 'mdi-email',
        text: 'Email',
        href: '/email',
        active: true,
        type: 'admin',
      },
      {
        icon: 'mdi-calendar ',
        text: 'Calendar',
        href: '/calendar',
        active: true,
        type: 'admin',
      },
      {
        icon: 'mdi-timer-outline ',
        text: 'Time Cards',
        href: '/time-cards',
        active: true,
        type: 'all',
      },
      {
        icon: 'mdi-google-drive ',
        text: 'Drive',
        href: '/drive',
        active: true,
        type: 'admin',
      },
      {
        icon: 'mdi-currency-usd ',
        text: 'Accounting',
        href: '/accounting',
        active: false,
        type: 'admin',
      },
      {
        icon: 'mdi-account-multiple',
        text: 'Users',
        href: '/users',
        active: true,
        type: 'admin',
      },
      {
        icon: 'mdi-cog',
        text: 'Settings',
        href: '/settings',
        active: true,
        type: 'admin',
      },
    ],
  }),

  mounted() {
    this.channel = this.$pusher.subscribe('my-channel');

    this.channel.bind('my-event', (data) => {
      this.socket(data);
    });
    setTimeout(() => (this.loader = false), 3000);

    switch (this.$route.path) {
      case '/projects':
        this.algoliaType = 'projects';
        break;
      case `/projects/${this.projectSelected.id}`:
        this.algoliaType = 'projects';
        break;
      case '/services':
        this.algoliaType = 'services';
        break;
      default:
        this.algoliaType = 'services';
        break;
    }
  },
  created() {
    this.currentTime = moment().format('LTS');
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
    if (!('geolocation' in navigator)) {
      this.errorStr = 'Geolocation is not available.';
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      },
    );
  },
  watch: {
    $route() {
      if (this.$route.path != '/services') {
        this.setSearch(null);
        this.searchAlgolia = '';
        this.algolia = [];
      }
    },
    user() {
      if (store.getters.isAuthenticated) {
        this.$store.dispatch('load');
      }
    },
    currentTime() {
      let time = this.currentTime.split(':');
      this.hour = time[0];
      this.minute = time[1];
      this.secons = time[2];
    },
    searchAlgolia() {
      if (this.searchAlgolia.length == 0) {
        this.setSearch(null);
        this.algolia = [];
      } else {
        switch (this.$route.path) {
          case '/projects':
            this.algoliaType = 'projects';
            break;
          case `/projects/${this.projectSelected.id}`:
            this.algoliaType = 'projects';
            break;
          case '/services':
            this.algoliaType = 'services';
            break;
          default:
            this.algoliaType = 'services';
            break;
        }
        let idStatusAlgolia = 0;
        if (this.algoliaType === 'projects') {
          idStatusAlgolia = this.idStatusProject
        } else {
          idStatusAlgolia = this.idStatus;
        }
        let post = [];
        var query = `query=${this.searchAlgolia}&highlightPreTag=__ais-highlight__&highlightPostTag=__%2Fais-highlight__&facets=%5B%5D&numericFilters=`;
        if (this.algoliaType == 'services') {
          query = `query=${this.searchAlgolia}&highlightPreTag=__ais-highlight__&highlightPostTag=__%2Fais-highlight__&facets=%5B%5D&numericFilters=status_id=${idStatusAlgolia}`;
        }
        if (this.idStatus == 0) {
          post = {
            requests: [
              {
                indexName: this.algoliaType,
                params: `query=${this.searchAlgolia}&highlightPreTag=__ais-highlight__&highlightPostTag=__%2Fais-highlight__&facets=%5B%5D&numericFilters=`,
              },
            ],
          };
        } else {
          post = {
            requests: [
              {
                indexName: this.algoliaType,
                params: query,
              },
            ],
          };
        }
        var stop = true;
        if (stop) {
          algolia.getAlgoliaSearch(post).then((response) => {
            this.algolia = response.data.results[0].hits;
          });
        }
      }
    },
  },
  methods: {
    ...mapMutations([
      'setService',
      'setproject',
      'setSearch',
      'setLocation',
      'resetState',
      'SET_CHECK',
      'SET_time_cards',
      'setNotifications',
      'setNotificationsCount',
      'addNotificationsCount',
      'deductNotificationsCount',
      'addService',
      'updateService',
      'deleteService',
      'addfileService',
      'setStatus',
      'deletefileService',
      'addProject',
      'sumProjectActives',
      'updateProject',
      'deleteProject',
      'updateEstimate',
      'deleteEstimate',
      'updateImageProject',
      'deleteFileProject',
      'addTicket',
      'updateTicket',
      'updTicket',
      'setNoteTicket',
      'deleteNotesTickets',
      'addfileTicket',
      'deletefileTicket',
      'addUser',
    ]),
    socket(data) {
      switch (data.message.type) {
        case 'addService':
          this.addService(data.message.data);
          break;
        case 'updateService':
          if (this.serviceSelected.id == data.message.data.id) {
            var service = { ...this.serviceSelected, ...data.message.data };
            this.setService(service);
          }
          this.updateService(data.message.data);
          break;
        case 'deleteService':
          this.deleteService(data.message.data);
          if (this.$route.path == `/services/${data.message.data}`) {
            this.$router.push('/services');
          }
          break;
        case 'setNote':
          if (this.serviceSelected.id == data.message.data.detail_id) {
            this.serviceSelected.notes.unshift(data.message.data);
          }
          break;
        case 'deleteNotes':
          if (this.serviceSelected.id == data.message.id) {
            this.serviceSelected.notes = data.message.data;
          }
          break;
        case 'setStatus':
          this.setStatus({
            idService: data.message.id,
            oldValue: data.message.old,
            newValue: data.message.data,
            status: data.message.status,
          });
          break;
        case 'updateImageService':
          this.addfileService(data.message);
          break;
        case 'filedeleteservice':
          this.deletefileService(data.message);
          break;
        case 'addProject':
          this.addProject(data.message.data);
          break;
        case 'updateProject':
          this.updateProject(data.message.data);
          break;
        case 'DeleteProject':
          if (this.$route.path == `/projects/${data.message.data}`) {
            this.$swal.fire('Deleted!', 'this project was deleted.', 'warning');
            this.$router.push('/projects');
          }
          this.deleteProject(data.message.data);
          break;
        case 'estimate':
          this.updateEstimate(data.message);
          break;
        case 'deleteEstimate':
          this.deleteEstimate(data.message);
          break;
        case 'updateImageProject':
          this.updateImageProject(data.message);
          break;
        case 'deleteproject':
          this.deleteFileProject(data.message);
          break;
        case 'addTicket':
          this.addTicket(data.message);
          break;
        case 'editTicket':
        case 'setStatusTicket':
          this.updateTicket(data.message);
          break;
        case 'setNoteTicket':
          this.setNoteTicket(data.message);
          break;
        case 'deleteNotesTickets':
          this.deleteNotesTickets(data.message);
          break;
        case 'updateImageTicket':
          this.addfileTicket(data.message);
          break;
        case 'filedeleteticket':
          this.deletefileTicket(data.message);
          break;
        case 'addUser':
          this.addUser(data.message);
          break;
        default:
          break;
      }
    },
    reload() {
      this.$store.dispatch('load');
    },
    goService(id) {
      this.$router.push(`/services/${id}`);
    },
    refresh() {
      this.refreshLoader = true;
      apis.getNotifications().then((reponse) => {
        this.refreshLoader = false;
        this.setNotificationsCount(reponse.data.total);
        this.setNotifications(reponse.data.data);
      });
    },
    changeNoty(item) {
      var read;
      if (item.read === true) {
        read = 1;
        this.addNotificationsCount();
      } else {
        read = 0;
        this.deductNotificationsCount();
      }
      let post = {
        read: read,
      };
      apis.changeNoty(item.id, post);
    },
    checkin() {
      this.loading = true;
      this.checkinvar = moment().format(this.dateFormat);
      var lon = null;
      var lat = null;
      if (this.location) {
        lon = this.location.coords.longitude;
        lat = this.location.coords.latitude;
      }
      let post = {
        user_id: this.user.id,
        check_in: this.checkinvar,
        lat: lat,
        lon: lon,
      };

      apis.checkin(post).then((reponse) => {
        this.$swal.fire(reponse.data.msg, '', 'success');
        this.idcheck = reponse.data.check.id;
        this.loading = false;
        this.check = true;

        let check = {
          checkin: true,
          clock: this.checkinvar,
          id: reponse.data.check.id,
        };
        this.SET_CHECK(check);
      });
    },
    checkout() {
      this.loading = true;
      this.checkoutvar = moment().format(this.dateFormat);

      var start = moment(this.clock.clock, 'HH:mm:ss');
      var end = moment(this.checkoutvar, 'HH:mm:ss');
      var durationhour = moment.duration(end.diff(start)).get('hours');
      var durationmin = moment.duration(end.diff(start)).get('minutes');
      var durationsec = moment.duration(end.diff(start)).get('seconds');
      var duration =
        durationhour +
        ' Hours ' +
        durationmin +
        ' Minutes ' +
        durationsec +
        ' Seconds';

      var lon = null;
      var lat = null;
      if (this.location) {
        lon = this.location.coords.longitude;
        lat = this.location.coords.latitude;
      }
      let post = {
        duration: duration,
        check_on: this.checkoutvar,
        lat: lat,
        lon: lon,
      };
      apis.checkout(this.clock.id, post).then((reponse) => {
        this.$swal.fire(reponse.data.msg, '', 'success');
        this.idcheck = null;
        this.loading = false;
        this.check = false;
        let check = {
          checkin: false,
          clock: null,
          id: null,
        };
        this.SET_CHECK(check);
      });
    },
    updateCurrentTime() {
      this.currentTime = moment().format('LTS');
    },
    async search(data, type) {
      this.btnLoader = true;
      if (type == 'tabla') {
        switch (this.$route.path) {
          case '/projects':
            this.setSearch(data);
            break;
          case `/projects/${this.projectSelected.id}`:
            this.$router.push('/projects');
            this.setSearch(data);
            break;
          case '/services':
            this.setSearch(data);
            break;
          default:
            this.$router.push('/services');
            this.setSearch(data);
            break;
        }
      } else {
        await google.geocode(data.address).then((reponse) => {
          this.setLocation({
            lat: reponse.data.results[0].geometry.location.lat,
            lng: reponse.data.results[0].geometry.location.lng,
          });
        });
        switch (this.$route.path) {
          case '/projects':
          case `/projects/${this.projectSelected.id}`:
            await apis.getProject(data.id).then((reponse) => {
              this.setproject(reponse.data);
              this.$router.push(`/projects/${data.id}`);
              this.searchAlgolia = '';
            });
            break;
          default:
            this.setService(data);
            this.$router.push(`/services/${data.id}`);
            this.searchAlgolia = '';
            break;
        }
      }
      setTimeout(() => (this.btnLoader = false), 1500);
    },
    searchuser(data) {
      this.btnLoader = true;
      setTimeout(() => this.setSearch(data), 1500);
      setTimeout(() => (this.btnLoader = false), 1500);
    },
    logout() {
      if (this.clock.checkin) {
        this.alert = true;
      } else {
        apis.logout().then((reponse) => {
          if (reponse.data.action) {
            localStorage.removeItem('user-token');
            localStorage.removeItem('user');
            this.resetState();
            this.$router.push('/login');
          }
        });
      }
    },
  },
};
</script>

<style lang="css">
.swal2-styled {
  font-family: 'Product Sans Regular', sans-serif;
}
.swal2-content {
  font-family: 'Product Sans Regular', sans-serif;
}
.swal2-styled.swal2-confirm {
  font-family: 'Product Sans Regular', sans-serif;
}
.swal2-title {
  font-family: 'Product Sans Regular', sans-serif;
}
@import 'assets/css/style.css';
.tool {
  font-size: 60px;
  padding: 24px;
}
@media (max-width: 425px) {
  .tool {
    font-size: 25px;
    padding: 24px;
  }
}
.algolia {
  position: fixed !important;
  z-index: 1000;
  top: 82px;
  overflow: scroll;
  height: 50vh;
}
.algolia-div {
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 1px #0000008c;
  cursor: pointer;
}
.ais-Hits {
  position: fixed;
  overflow: scroll;
  height: 350px;
  margin-top: 10px;
  margin-left: -26px;
}
.btn-reload {
  cursor: pointer;
  font-family: 'Product Sans Regular', sans-serif;
}
</style>
