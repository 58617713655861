<template>
  <div class='home' justify='center'>
    <Carts />
  </div>
</template>

<script>
// @ is an alias to /src
import Carts from '@/components/Carts.vue';

export default {
  name: 'Home',
  components: {
    Carts,
  },
};
</script>
