import apis from '@/apis';
export const actions = {
  load({ commit }) {
    apis
      .getDates()
      .then((r) => r.data)
      .then((dates) => {
        commit('setDates', dates);
      });
    apis
      .getConfiguration()
      .then((r) => r.data)
      .then((data) => {
        let list = [];
        data.terms.map((term) => {
          let parse = JSON.parse(term.value);
          let name = parse.name;
          let text = parse.text;
          let ter = {
            id: term.id,
            name: name,
            text: text,
          };
          list.push(ter);
        });
        commit('setServiceActives', data.active_sevices);
        commit('setProjectActives', data.active_projects);
        commit('setNotificationsCount', data.notifications.total);
        commit('setNotifications', data.notifications.data);
        commit('setTerms', list);
        commit('setTaxes', data.taxes);
        commit('setRoles', data.roles);
        commit('setTechs', data.techs);
        commit('setClients', data.customers);
        commit('setManagers', data.managers);

        commit('setStatusServices', data.status_services);
        commit('setStatusProjects', data.status_projects);
        commit('setStatusTickets', data.status_tickets);
      });
  },
  cheks({ commit }, data) {
    apis
    .getCheks(data)
    .then((r) => r.data)
      .then((time_cards) => {
        commit('serTimeCards', time_cards);
        return true;
      });
  },
  status({ commit }) {
    apis.getStatus().then((r) => r.data)
      .then((status) => {
        commit('setStatus', status);
      });
  },
};
