import axios from 'axios';
const name = process.env.VUE_APP_API_ALGOLIA_NAME;
const key = process.env.VUE_APP_API_ALGOLIA;
function getAlgoliaSearch(post) {
  return axios.post(
    `https://mer8iwu9gk-dsn.algolia.net/1/indexes/*/queries?x-algolia-agent=Algolia for JavaScript (4.11.0); Browser (lite); instantsearch.js (4.33.0); Vue (2.6.12); Vue InstantSearch (4.1.0); JS Helper (3.6.2)&x-algolia-api-key=${key}&x-algolia-application-id=${name}`,
    post,
    {
      'x-algolia-application-id': `${name}`,
      'content-type': 'application/x-www-form-urlencoded',
      'x-algolia-api-key': `${key}`,
      Accept: '*/*',
      'Accept-Language':
        'es-419,es;q=0.9,es-ES;q=0.8,en;q=0.7,en-GB;q=0.6,en-US;q=0.5,pt;q=0.4',
    },
  );
}
export default {
  getAlgoliaSearch,
};
