import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

const baseUrl = process.env.VUE_APP_BASE_URL_API ?? 'http://localhost';

const instance = axios.create({
  baseURL: `${baseUrl}/api/v1`,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('user-token') || '';
  config.headers.Authorization =  `Bearer ${token}`;

  return config;
});
function sendForm(url, post, headers){
  return instance.post(url, post, headers)
}
function getNotifications() {
  return instance.get('/notifications');
}

function getConfiguration() {
  return instance.get('/configuration');
}
function getDates() {
  return instance.get('/getDates');
}
function getCheks(data) {
  return instance.get(
    `/check-get?page=${data.page}&per_page=${data.itemsPerPage}`,
  );
}
function getStatus() {
  return instance.get('/status');
}
function changeNoty(id, post) {
  return instance.post(`/notification/${id}`, post);
}
function checkin(post) {
  return instance.post('/check-in', post);
}
function checkout(id, post) {
  return instance.post(`/check-out/${id}`, post);
}
function getProject(id) {
  return instance.get(`/project/${id}`);
}
function logout() {
  return instance.get('/logout');
}
function notesActions(post) {
  return instance.post('/notesActions', post);
}
function setTodos(id, post) {
  return instance.patch(`/todos/${id}`, post);
}
function addTodos(post) {
  return instance.post('/todos', post);
}
function deleteService(id) {
  return instance.delete(`/delete-service/${id}`);
}
function updateService(id, post) {
  return instance.patch(`/services/${id}`, post);
}
function logsService(id) {
  return instance.get(`/services-logs/${id}`);
}
function logsProjects(id) {
  return instance.get(`/projects-logs/${id}`);
}
function logsTickets(id) {
  return instance.get(`/tickets-logs/${id}`);
}
function deleteTicket(id) {
  return instance.delete(`/delete-ticket/${id}`);
}
function updateTicket(id, post) {
  return instance.patch(`/tickets/${id}`, post);
}
function addEstimate(post) {
  return instance.post('/estimatesProject', post);
}
function addService(post) {
  return instance.post('/services', post);
}
function addProject(post) {
  return instance.post('/projects', post);
}
function addTicket(post) {
  return instance.post('/tickets', post);
}
function getUrl(url) {
  return instance.get(url);
}
function login(post) {
  return instance.post('/login', post);
}
function addUSer(post) {
  return instance.post('/add-user', post);
}
function deleteImagesSelected(post) {
  return instance.post('/multimedias/deleteImgs', post);
}
function sendPost(url, post) {
  return instance.post(url, post);
}
function sendDelete(url) {
  return instance.delete(url);
}
function sendPatch(url, post) {
  return instance.patch(url, post);
}

function loginEmail(post) {
  return instance.post(`${baseUrl}/api/v1/loginEmail`, post);
}

function getTicket(id) {
  return instance.get(`/ticket/${id}`);
}

export default {
  instance,
  getNotifications,
  getConfiguration,
  getDates,
  getCheks,
  getStatus,
  changeNoty,
  checkin,
  checkout,
  getProject,
  logout,
  loginEmail,
  login,
  notesActions,
  setTodos,
  addTodos,
  deleteService,
  updateService,
  deleteTicket,
  updateTicket,
  addEstimate,
  addService,
  addProject,
  addTicket,
  getUrl,
  addUSer,
  deleteImagesSelected,
  sendPost,
  sendDelete,
  sendPatch,
  sendForm,
  logsService,
  logsProjects,
  logsTickets,
  getTicket,
};
