import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VuePlaceAutocomplete from 'vue-place-autocomplete';
import * as VueGoogleMaps from "vue2-google-maps";
import LoadScript from 'vue-plugin-load-script';
import GoogleAuth from '@/config/installGoogleAuthPlugin.js'
import VueGooglePlaces from 'vue-google-places'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import InstantSearch from 'vue-instantsearch';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(LoadScript);
Vue.use(VueGooglePlaces, { load: { key: `${process.env.VUE_APP_API_KEY_MAPS}`, libraries: 'places', v: 3.38 } })
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_KEY_MAPS,
    libraries: 'places'
  },
  installComponents: true,
});
Vue.use(InstantSearch);
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

const gauthOption = {
  clientId: process.env.VUE_APP_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)
Vue.use(VuePlaceAutocomplete);
Vue.config.productionTip = false
Vue.use(require('vue-pusher'), {
  api_key: 'b011b62e12aa2d962096',
  options: {
      cluster: 'us2',
      encrypted: true,
  }
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
