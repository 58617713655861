import Vue from 'vue';
import Vuex from 'vuex';
import VueLazyLoad from 'vue-lazyload';
import VuexPersistence from 'vuex-persist';
import { state } from './state';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

Vue.use(Vuex);
Vue.use(VueLazyLoad);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: [vuexLocal.plugin],
});
